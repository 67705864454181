import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { GlobalLangService } from '../../../services/global-lang.service';

@Component({
  selector: 'app-session-filters',
  templateUrl: './session-filters.component.html',
  styleUrls: ['./session-filters.component.css']
})
export class SessionFiltersComponent implements OnInit {
  form: FormGroup;
  subAgeGroupData = [];
  ageGroupData = [];
  coachData = [];
  translate: any;
  academyResponsibleFor: string = '';
  constructor(public dialogRef: MatDialogRef<SessionFiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
    private globalService: GlobalLangService,
    public api: ApiService) {
    this.form = this.fb.group({
      coachName: [''],
      ageGroup: [''],
      yob: [''],
      subAgeGroup: [''],
    });

    console.log('this.data ', this.data);
    this.globalService.languageDataObj.subscribe((data: any) => {
      if (data.TRAININGSESSIONFILTERS) {
          this.translate = data.TRAININGSESSIONFILTERS;
      }
  })
  }

  ngOnInit() {
    this.academyResponsibleFor = localStorage.getItem("academyResponsibleFor");
    this.form.get('ageGroup').valueChanges.subscribe(res => {
      if (res) { 
        this.ageGroupData.forEach(obj => {
          if(res == obj.id)
          {
          console.log("obj ", obj);
            this.subAgeGroupData = obj.subAgeGroup;
          }
          })
        this.form.patchValue({subAgeGroup:''});
        console.log('AG ',this.ageGroupData);
        //this.loadSubAgeGroup(res);
      }
    });
    console.log(this.data);
    this.coachData = this.data.coachData;
    this.ageGroupData = this.data.ageGroupData;
    if(this.data.filterByCoach)
    {
      this.form.patchValue({coachName:this.data.filterByCoach});
    }
    if(this.data.filterByAgeGroup)
    {
      this.form.patchValue({ageGroup:this.data.filterByAgeGroup});
    }
    if(this.data.filterByYob)
    {
      this.form.patchValue({yob:this.data.filterByYob});
    }
    if(this.data.filterBySubAgeGroup)
    {
      this.form.patchValue({subAgeGroup:this.data.filterBySubAgeGroup});
    }
  }
  
  resetForm() {
    this.form.reset();
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }
  loadSubAgeGroup(ageId: string) {

    let useridVal = localStorage.getItem("UsrID");
    const apiobj: any = {};
    apiobj.agegroupid = ageId;
    apiobj.UsrID = useridVal;
    apiobj.IsSubAgeGroup = true;
    this.api.AcademyGetData(this.api.apiUrl.Subgetgaloksubagegroupslist, apiobj, true)
      .subscribe(res => {
        this.subAgeGroupData = res.data;
      },
        () => {
        });
  }
  submit() {
    this.dialogRef.close(this.form.value);
  }
}
