import { environment } from '../../environments/environment';
const RMAcademyapiEndpoint = environment.RMapiBaseUrl;
export const apiUrl = {
    playerposts: 'admin/playerPosts',
    playerDashboard: 'admin/playerDashboard',
    playerBookingList: 'admin/PlayersBookingList',
    addAmenity: 'admin/addAmenities',
    addCity: 'admin/addCities',
    addField: 'admin/addField',
    addFieldSize: 'admin/addFieldsize',
    addGroundType: 'admin/addGroundTypes',
    addMatchType: 'admin/addMatchTypes',
    addPosition: 'admin/addPosition',
    addSponsor: 'admin/addSponsors',
    addToSubscribers: 'admin/addToSubscribers',
    addVersion: 'admin/addVersion',
    gliveStatus: 'admin/gliveStatus',
    gliveAction: 'admin/gliveAction',
    addCountry: 'admin/addcountry',
    addState: 'admin/addstates',
    adminEarning: 'admin/adminEarning',
    blockField: 'admin/blockField',
    blockUnblockData: 'admin/blockUnblockData',
    favouritePlayer: 'admin/favoritePlayer',
    broadcastMessage: 'admin/broadcastMessage',
    contactDetails: 'admin/contactDetails',
    addContract: 'admin/createContract',
    addAdvertisement: 'admin/createEditAdvertisement',
    addFeature: 'admin/addEditLiveStreams',
    featuredList: 'admin/getLiveStreams',
    addEditAdvertisement: 'admin/addEditAdvertisement',
    addPromoCode: 'admin/createEditPromoCode',
    addOwner: 'admin/createOwnerByAdmin',
    dashBoard: 'admin/dashBoard',
    deleteAmenity: 'admin/deleteAmenities',
    removeAcademy: 'newapi/removeAcademy',
    deleteContract: 'admin/deleteContract',
    deleteData: 'admin/deleteData',
    deleteFieldSize: 'admin/deleteFieldSize',
    deleteFieldType: 'admin/deleteFieldType',
    deleteLocality: 'admin/deleteLocality',
    deleteMatchType: 'admin/deleteMatchType',
    deletePosition: 'admin/deletePosition',
    deleteQuery: 'admin/deleteQueries',
    deleteReportedPost: 'admin/deleteReportedPost',
    fieldDetails: 'admin/fieldDetails',
    gameDetails: 'admin/gameDetails',
    getAdRevenueDetails: 'admin/getAdRevenueDatals',
    ads: 'admin/getAds',
    getAdvertisements: 'admin/getAdvertisements',
    broadCasts: 'admin/getBroadCast',
    cities: 'owner/citiesList1',
    getCompleteRevenueData: 'admin/getCompleteRevenueData',
    contracts: 'admin/getContracts',
    countries: 'admin/getCountryList',
    getFieldData: 'admin/getFieldData',
    getListing: 'admin/getListing',
    getMailChimpList: 'admin/getMailChimpList',
    owners: 'admin/getOwnerList',
    promoCodes: 'admin/getPromoCode',
    queries: 'admin/getQueries',
    revenueData: 'admin/getRevenueData',
    getSlotsPromotion: 'admin/getSlotsPromotion',
    sponsors: 'admin/getSponsors',
    states: 'admin/getStatesList',
    getSubscribers: 'admin/getSubscribers',
    getFieldWithContract: 'admin/getfieldWithContract',
    fieldSizes: 'admin/listFieldSize',
    fieldTypes: 'admin/listFieldType',
    matchTypes: 'admin/listMatchTypes',
    listPickupGames: 'admin/listPicupGames',
    listPlayers: 'admin/listPlayers',
    playersData: 'admin/playersData',
    listReportedPost: 'admin/listReportedPost',
    listVersion: 'admin/listVersion',
    login: 'admin/login',
    pickUpGameCount: 'admin/pickupgameCount',
    postQueries: 'admin/postQueries',
    subscribeMailChimp: 'admin/subscribeMailChimp',
    updateCity: 'admin/updateCity',
    updateCountry: 'admin/updateCountry',
    updateFieldSize: 'admin/updateFieldSize',
    updateFieldType: 'admin/updateFieldType',
    updateMatchType: 'admin/updateMatchType',
    updatePasswordViaLink: 'admin/updatePasswordViaLink',
    updatePlayerProfile: 'admin/updatePlayerProfile',
    updateProfile: 'admin/updateProfile',
    updateState: 'admin/updateState',
    uploadImage: 'admin/uploadImageOrVideo',
    amenities: 'owner/getAmenities',
    positions: 'owner/listPosition',
    checkUsername: 'owner/checkUsername',
    reports: 'admin/getReports',
    playerReport: 'admin/playerReport',
    groundRelatedData: 'owner/groundRelatedData',
    generateReport: 'admin/generateReport',
    getOwnerQueries: 'admin/getOwnerQueries',
    getAssignedAgeGroup: 'academy/ageGroupWiseData',
    addCamerasToField: 'admin/addCamerasToField',
    fetchReferrals: 'admin/fetchReferrals',
    getGameDetails: 'admin/gameDetails',
    getCurrencies: 'admin/getCurrencies',
    updateGame: 'admin/editGame',
    addEditBrands: 'admin/addEditBrands',
    addEditClubs: 'admin/addEditClubs',
    addEditCurrencies: 'admin/addEditCurrencies',
    verifyOrganizer: 'admin/verifiedOrganizer',
    getRegistrationReport: 'admin/getRegistrationReport',
    getOnlineUsers: 'admin/onlineUsers',
    getAdmins: 'admin/getAdmins',
    addEditAdmin: 'admin/addEditAdmin',
    blockUnblockAdmin: 'admin/blockUnblockData',

    /* New */

    onlineUsers: 'admin/onlineUsersV2',

    // Graph
    registrationGraph: "admin/registrationGraph",
    gameGraph: "admin/gameGraph",
    fieldgraph: "admin/feedGraph",
    bookingGraph: "admin/bookingGraph",
    overallGraph: "admin/overallGraph",

    /* new */

    gameschartgameGraphV2: "admin/gameGraphV2",
    registrationGraphV2: "admin/registrationGraphV2",
    feedGraphV2: "admin/feedGraphV2",
    bookingGraphV2: "admin/bookingGraphV2",
    overallGraphV2: "admin/overallGraphV2",
    registrationGraphV3: "admin/registrationGraphV3",

    //tryout
    getClubAccounts: "admin/getClubAccounts",
    getUpcomingTryouts: "admin/getUpcomingTryouts",
    getTryoutDetails: "admin/getTryoutDetails",
    getTryoutPlayerData: "admin/playersData",
    getacceptRejectRequest: "admin/acceptRejectRequest",
    getPastTryouts: "admin/getPastTryouts",
    getlockUnlockPlayer: "admin/lockUnlockPlayer",
    getPlayersTryOuts: "admin/getPlayersTryOuts",
    getapproveDeclineForTryout: "admin/approveDeclineForTryout",
    getsearchclubPlayers: "admin/searchPlayer",
    getaddPlayerstoclub: "admin/addPlayersToClub",

    // GGO Reports
    getSearchplayers: "admin/searchPlayer",
    getmarkUnmarkGGO: "admin/markUnmarkGGO",
    getGGOReport: "admin/getGGOReport",
    getforgotpasswordlink: "api/auth/forgotPassword",


    //Portal Academy API ----------------------------------begin

    //get-account
    getGaAcadyAccount: "academy/getGaAcadyAccount",

    addcadyRegistration: "register/addaccount",
    getacadyRegList: "register/acadyaccountlist",

    verifynewaccreg: "pracdemy/verifynewaccreg",
    verifynewaccregbyuserid: "pracdemy/verifynewaccregbyuserid",
    pracadyverify: "pracdemy/acadyverify",
    praddregacc: "pracdemy/addregacc",
    praddregacc1: "pracdemy/addregacc1",
    praddregacc2: "pracdemy/addregacc2",
    praddregacc3: "pracdemy/addregacc3",
    praddregacc4: "pracdemy/addregacc4",
    accapprove: "pracdemy/accapprove",
    addacdyfilesUpdate: "pracdemy/acdyupdatefile",
    acdyupdatefiledynamic: "pracdemy/acdyupdatefiledynamic",
    acadyfilesGallerylist: "pracdemy/acadyfilesGallerylist",
    acadyfilesGalleryRemove: "pracdemy/acadyfilesGalleryRemove",
    getGaAcadyFilesByID: "pracdemy/getGaAcadyFilesByID",
    getacadylist: "pracdemy/getacadylist",
    getacadylistByUsrID: "pracdemy/getacadylistByUsrID",
    gettotalacadyproperty: "pracdemy/gettotalacadyproperty",
    getAcademyPlayers: "academy/getAcademyPlayers",
    getDashboard: "pracdemy/getDashboard?userId=",
    getPlayersCount: 'academy/getPlayerDashboard',

    prgetregacc: "pracdemy/getregacc",
    getownerCountry: "owner/countryList",
    getownerCity: "owner/citiesList1",
    acadyaddReg: "api/acd/addacdReg",
    getacdReg: "api/acd/getacdReg",
    newAuthlogin: "academy/login",
    verifyAccount: "academy/verifyAccount",
    changePassword: "api/auth/changePassword",
    fileupload: "fileupload/uploadAny",


    //get all list
    acclist: 'acclist/getallacc',
    frontPraddaccount: "academy/addaccount",
    frontgetallacadyRegList: "pracdemy/getregacc",
    acadyverify: "pracdemy/acadyverify",

    //inside 
    getAcademy: "academy/getAcademy",
    authlogin: "api/auth/login",
    //cms 
    //Academy - Admin Panel
    cmsgetAcadylist: "cmsacademy/cmsacadylist",
    adminGetAcademy: "academy/admin/getAcademy",
    cmsgetAcadyregview: "cmsacademy/cmsacadyregview",
    cmsacadycntrapp: "cmsacademy/cmsacadycntrapp",
    adminFileUpload: 'academy/admin/fileUpload',
    adminUploadContract: 'academy/admin/createAcademy',
    adminGetInsidePlayers: 'academy/admin/getPlayers',
    adminGetOutsidePlayers: 'academy/admin/getAcademyPlayers',
    adminGetCoaches: 'academy/admin/getCoach',
    adminGetTrainigs: 'academy/admin/getTraining',

    //remove contract
    removeContract: "newapi/removeContract",
    removePlayerInfo: "newapi/removeId",
    //UploadImage
    acadyuploadImage: "fileupload/uploadAny",

    //misc
    getnotification: "notification/getnotification",
    getUserNotification: "academy/getNotifications",
    getAdminNotification: "academy/admin/getNotifications",
    getUserNotificationCount: "academy/notificationStatus",
    getAdminNotificationCount: "academy/admin/notificationStatus",
    addacdyfiles: "pracdemy/addacdyfiles",
    getdefaultmsgbyno: "prlokup/getdefaultmsgbyno",
    getCoach: "prlokup/getcoach",
    getsubagegroup: "prlokup/getsubagegroup",


    //coache
    getallcoach: "prcoach/getallcoach",
    createCoach: "academy/createCoach",
    updateCoach: "academy/editCoach",
    removeCoach: 'academy/removeCoach',
    getcoachbyid: "prcoach/getcoachbyid",
    getcoachlbyacadyuserid: "prcoach/getcoachlbyacadyuserid",
    getcoachbyUseridAcadyidCoachid: "prcoach/getcoachbyUseridAcadyidCoachid",
    getAllCoaches: "academy/getCoach",
    gellallCoachbyUserIDAcadyID: "prcoach/gellallCoachbyUserIDAcadyID",
    getcoachlbyacadyuseridOnly: "prcoach/getcoachlbyacadyuseridOnly",
    getcoachlbyacadyuseridAgeGroupSubAgegroup: "prcoach/getcoachlbyacadyuseridAgeGroupSubAgegroup",
    //lok-up
    getacadycategorylist: "prlokup/getacadycategorylist",
    getageList: "prlokup/getageList",
    getagegrouplist: "prlokup/getagegrouplist",
    // getagegrouplistNew: "newapi/getAcademyAgeGroups?academyId=5fc798407d1a5e01ecf1f557",
    getsubagegrouplist: "prlokup/getsubagegrouplist",
    getloklist: "prlokup/getgaloktimes",
    getlokdurationlist: "prlokup/getgalokdurations",
    getlokcurrency: "prlokup/getgalokcurrency",
    deleteGaLokSubAgeGroups: "prlokup/deleteGaLokSubAgeGroups",
    deleteGaLokSubAgeGroupsByID: "prlokup/deleteGaLokSubAgeGroupsByID",
    deleteMainGaLokSubAgeGroups: "prlokup/deleteMainGaLokSubAgeGroups",
    createSubAgeGroups: "prlokup/createSubAgeGroups",
    getgaloksubagegroupslist: "prlokup/getgaloksubagegroupslist",
    getnextName: "academy/subAgeGroupWiseData",
    updateGaLokSubAgeGroupstotplayers: "prlokup/updateGaLokSubAgeGroupstotplayers",
    Subgetgaloksubagegroupslist: "academy/subAgeGroupWiseData",
    //acadyuser
    getverifypruseremail: "prtuser/getverifyuseremail",
    getverifyacadyaccbyphone: "academy/getacadyaccByPhone",
    getverifyacadyaccbyemail: "academy/getacadyaccByEmail",

    //agegroup
    createagegroups: "pragegroup/createAssignAgeGroupToCoach",
    NewcreateAssignAgeGroupToCoach: "pragegroup/NewcreateAssignAgeGroupToCoach",
    getAssignAgeGroupToCoach: "pragegroup/getAssignAgeGroupToCoach",
    deleteAssignAgeGroupToCoach: "pragegroup/deleteAssignAgeGroupToCoach",
    getAssignAgeGroupToCoachList: "pragegroup/getAssignAgeGroupToCoachList",
    getCoachByUAAgeSubAge: "pragegroup/getCoachByUAAgeSubAge",


    //gameschedule
    createGameschedule: "pracdemy/createGameschedule",
    getgameeschedulelist: "pracdemy/getgameeschedulelist",

    //academy traiining
    createtrainingGame: "acadytra/createtra",
    gettrainingGamelist: "acadytra/gettrainingGamelist",

    getTraining: "prtraining/getTraining",
    getTrainingRate: 'academy/getActivePlayers',
    getTrainingData: 'academy/getTraining?',
    delTraining: "academy/removeTraining",

    //prtuser
    getprtuserlist: "prtuser/getGameslbyacadyuserid",
    getallprtuserByUsrEmailAddressOnly: "prtuser/getallprtuserByUsrEmailAddressOnly",
    getPlayersAttendance: "academy/getPlayerAttendances",
    submitRating: 'academy/submitRating',
    getPlayersRating: 'academy/getPlayerRatings',
    getRatings: 'academy/getRatings',
    //cms- usermanagment
    getAllMenu: "usermanagment/getAllMenu",
    getAllmenuForList: "usermanagment/getAllmenuForList",
    getmenuByID: "usermanagment/getmenuByID",
    deletemenuByID: "usermanagment/deletemenuByID",
    createMenu: "usermanagment/createMenu",
    updateMenu: "usermanagment/updateMenu",

    getAllRoles: "usermanagment/getAllRoles",
    getAllRoleForList: "usermanagment/getAllRoleForList",
    getAllUsers: "usermanagment/getAllUsers",

    assignRoleToUser: "usermanagment/assignRoleToUser",
    createRole: "usermanagment/createRole",
    updateRole: "usermanagment/updateRole",
    getRolDetailsbyID: "usermanagment/getRolDetailsbyID",
    deleteRoleByID: "usermanagment/deleteRoleByID",
    getlogin: "usermanagment/login",
    deleteSubMenuByID: "usermanagment/deleteSubMenuByID",

    deleteLanguageCode: "usermanagment/deleteLanguageCode",
    getAllLanguages: "usermanagment/getAllLanguages",
    createLanguageCode: "usermanagment/createLanguageCode",
    updateLanguageCode: "usermanagment/updateLanguageCode",
    getLanguageDetailByID: "usermanagment/getLanguageDetailByID",






    //acady players
    getacadysubscribPlayerlist: 'academy/getacadysubscribPlayerlist',
    createacadysubscribPlayerCntr: 'pracadysubrsplayercontract/createAcdysubrsplayercontract',
    addUpdateContract: 'academy/manageContract',
    getacadysubrsplayercntr: 'pracadysubrsplayercontract/getacadysubrsplayercntr',
    getReceipts: 'academy/getReceipts',
    receiptStats: 'academy/receiptStats',
    //Auth
    apitokenforThirdparty: 'api/auth/apitokenpass',
    allCountryData: 'academy/getCountries',
    fileUploadLiveNode: 'academy/fileUpload',
    apitokenpassFun: 'api/auth/apitokenpassFun',
    apitokenpassRating: 'api/auth/apitokenpassRating',
    apitokenpassFunRemoveSubAgeGroup: 'api/auth/apitokenpassFunRemoveSubAgeGroup',
    //academy --player--traing
    createAcadyCoachTraInfo: 'acadycoachtrainfo/createAcadyCoachTraInfo',
    updateAcadyCoachTra: 'acadycoachtrainfo/updateAcadyCoachTra',
    createAcadyCoachTra: 'acadycoachtrainfo/createAcadyCoachTra',
    getAcadyCoachTra: 'acadycoachtrainfo/getAcadyCoachTra',
    getAcadyCoachTraInfo: 'acadycoachtrainfo/getAcadyCoachTraInfo',
    removeAcadyCoachTra: 'acadycoachtrainfo/removeAcadyCoachTra',
    getTrainfo: 'training/getTrainfo',
    getTra: 'training/gettra',
    removeTraInfo: 'training/removeTrainfo',
    getTrainfoTra: 'training/getTrainfoTra',
    createTraining: 'academy/createTraining',
    editTraining: 'academy/editTraining',
    //thirdparty API
    tpgetPlayers: 'academy/getPlayers',

    //academy--subscr-rating
    createPlayerRating: 'rating/createPlayerRating',
    getPlayerRatingByID: 'rating/getPlayerRatingByID',
    updatePlayerRating: 'rating/updatePlayerRating',
    createMultiple: 'rating/createMultiple',
    getratingAvg: 'rating/getratingAvg',

    thaacademyaddPlayers: 'academy/addPlayers',
    signUp:'academy/signup',

    //gamez
    createGames: 'prgames/createGames',
    updateGames: 'prgames/updateGames',
    getAllgames: 'prgames/getAllgames',
    deleteGames: 'prgames/deleteGames',
    getGames: 'prgames/getGames',
    updateGamesScore: 'prgames/updateGamesScore',
    //pr notification
    getnotificationByUsrID: "prnotification/getnotificationByUsrID",

    //remote URL
    rmaddPlayers: 'academy/addPlayers',
    rmassignSubGroupToInSidePlayers: 'academy/assignSubGroupToPlayers',
    rmassignSubGroupToOutSidePlayers: 'newapi/assignSubGroupToPlayers',
    rmgetAgeGroups: RMAcademyapiEndpoint + 'academy/getAgeGroups',
    rmgetCities: RMAcademyapiEndpoint + 'academy/getCities',
    rmgetCountries: RMAcademyapiEndpoint + 'academy/getCountries',
    rmgetCurrencies: RMAcademyapiEndpoint + 'academy/getCurrencies',
    rmgetPlayers: 'academy/getPlayers',
    rmgetPositions: RMAcademyapiEndpoint + 'academy/getPositions',
    rmsearchPlayers: 'academy/searchPlayers',
    addNewPlayer: 'academy/createNewPlayer',
    removerPlayer: 'academy/deletePlayer',
    getContractCurrencies: 'academy/getCurrencies',
    invoiceDetails: 'academy/invoiceDetails',
    invoiceDownload: 'academy/invoiceDownload',
    
    rmassignSubGroupToPlayers: RMAcademyapiEndpoint + 'academy/assignSubGroupToPlayers',
};

