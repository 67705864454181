import {Injectable} from '@angular/core';
import {LocalStorageService} from './local-storage.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import { PopupComponent } from '../popup/popup.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(
        private router: Router,
        private localStorage: LocalStorageService,
        public dialog: MatDialog
    ) {

        /********* Check JSON parse error on fetching currentUser from local storage **********/
        let _user: any = null;
        try {
            _user = this.localStorage.getItem('web_user');
        } catch (error) {
            if (error instanceof SyntaxError) this.removeUser();
        }
        this.currentUserSubject = new BehaviorSubject<any>(_user);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    /********* Get the current value of the logged in user **********/
    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    /********* Get the current user token **********/
    public get getUserToken(): string {
        if (!!this.currentUserValue) return this.currentUserValue.accessToken;
    }

    /********* Set user in local storage **********/
    public setUserLocalData(userData: any) {
        this.localStorage.setItem('web_user', JSON.stringify(userData));
        this.currentUserSubject.next(userData);
    }

     public hasPermission(permissions: string | string[]): boolean 
     {
         //all set true for test
         return true;
     }

/* new from rmt */
    public old_old_hasPermission(permissions: string | string[]): boolean {

        if (!permissions || Array.isArray(permissions) && !permissions.length) {
          return true; // every role has blank permission
        }
    
        //const currentUser = JSON.parse(this.currentUserValue);
        const currentUser = (this.currentUserValue);
        let myPermission = [];
    
        if(!(currentUser.rolePermission != undefined && currentUser.rolePermission.assignedMenu.length)){
          return false;
        }
    
        currentUser.rolePermission.assignedMenu.forEach(element => {
          myPermission.push({
            name: element.MenuID.menuName,
            value: element.MenuID.menuValue
          })
    
          if (element.subMenu.length) {
            element.subMenu.forEach(childElem => {
              myPermission.push({
                name: childElem.subMenuName,
                value: childElem.SubMenuValue
              })
            });
          }
        });
    
        if (!currentUser) {
          console.log("step my2 check")
          return false;
        }
    
        if (currentUser.usertype == "CMS-ADMIN") {
          return true;
        }
    
        if (typeof permissions === 'string') {
          console.log("step 1 check" + myPermission.find((item) => item.value.toLowerCase() === permissions.toLowerCase()))
          return !!myPermission.find((item) => item.value.toLowerCase() === permissions.toLowerCase());
          
          // currentUser.permissions.find((item) => item.toLowerCase() === permissions.toLowerCase());
        }
    
        for (const permission of permissions) {
          console.log("step 1 check" +  myPermission.find((item) => item.value.toLowerCase() === permission.toLowerCase()));
          const value = myPermission.find((item) => item.value.toLowerCase() === permission.toLowerCase());
          if (value) {
            return true;
          }
        }
    
        return false;
      }

    public old_hasPermission(permissions: string | string[]): boolean {
        if (!permissions || Array.isArray(permissions) && !permissions.length) {
            return true; // every role has blank permission
        }

        const currentUser = this.currentUserValue;
        if (!currentUser) {
            return false;
        }

        if (currentUser.superAdmin) {
            return true;
        }

        if (!currentUser.permissions.length) {
            return false;
        }

        if (typeof permissions === 'string') {
            return !!currentUser.permissions.find((item) => item.toLowerCase() === permissions.toLowerCase());
        }

        for (const permission of permissions) 
        {
            const value = currentUser.permissions.find((item) => item.toLowerCase() === permission.toLowerCase());
            if (value) {
                return true;
            }
        }

        return false;
    }

    /********* Remove user from local storage **********/
    public removeUser() {
        this.localStorage.removeItem('web_user');
        if (this.currentUserSubject) {
            this.currentUserSubject.next(null);
        }
    }

    /********* User Sign-Out **********/
    public userSignOut() {
      
      // let contractStatus = JSON.parse( localStorage.getItem('contractStatus'));
        // if(!contractStatus){
            
        //     let dialogRef = this.dialog.open(PopupComponent, {
        //       width: '650px',
        //       data: {
        
        //       }
        //     });
        
        //     dialogRef.afterClosed().subscribe(result => {
        //       console.log('The dialog was closed');
              
        //       if (result.completeReg) {
        //         this.router.navigate(['/signupform']);
        
        //       }
        //       else {
        //         window.location.reload();
        //       }
        
        //     });
        
          
        // }
        this.removeUser();
        this.router.navigate(['/login']);
        localStorage.clear();
    }

    public getmenus(): any[] {
     

        let myMenus = [];
        const currentUser =  (this.currentUserValue);
        console.log('cusr ',currentUser);
        if(currentUser.userType == "academyUser")
        {
            myMenus.push(
                {
                    name: 'Home',
                    value: 'dboard',
                    orderid : 1, 
                    icon: 'ic_home.png',
                    subMenu : []
                },
                {
                    name: 'Academy',
                    value: 'addacadyreg1',
                    orderid : 2,
                    icon: 'ic_academy.png',
                    subMenu : []
                },
                /*{
                    name: 'Setting',
                    value: 'setting',
                    orderid : 3,
                    icon: 'ic_settings.png',
                    subMenu : []
                },*/
                {
                    name: 'Age Groups',
                    value: 'agegrouplist',
                    orderid : 4,
                    icon: 'ic_age_group.png',
                    subMenu : []
                },
                {
                    name: 'Permission',
                    value: 'permission',
                    orderid : 5,
                    icon: 'ic_permissions.png',
                    subMenu : []
                },
                {
                    name: 'Logout',
                    value: 'logout',
                    orderid : 6,
                    icon: 'ic_logout.png',
                    subMenu : []
                },
            )
        }
        return myMenus;
    }

    /*public getmenus(): any[] {
     

        let myMenus = [];
    
        //const currentUser = JSON.parse(this.currentUserValue);
        const currentUser =  (this.currentUserValue);
    
    
        if (!(currentUser.rolePermission != undefined && currentUser.rolePermission.assignedMenu.length)) {
          return myMenus;
        }
    
        currentUser.rolePermission.assignedMenu.forEach((element,index) => {
        
          try
          {

          myMenus.push({
           
            name: element.MenuID.menuName,
            value: element.MenuID.menuValue,
            orderid : element.MenuID.orderid,
            subMenu : []
          })
    
          if (element.subMenu.length) {
            element.subMenu.forEach(childElem => {
              myMenus[index].subMenu.push({
                name: childElem.subMenuName,
                value: childElem.SubMenuValue,
                orderid : childElem.orderid
              })
            });
          }

        }
        catch{}
        {
          
        }

        });

       // myMenus.forEach(element => {
          
       // });

        myMenus.sort((a,b) => 0 - (a.orderid > b.orderid ? -1 : 1));
    
        return myMenus;
      }*/
}
