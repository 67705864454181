import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { GlobalLangService } from 'src/app/services/global-lang.service';
import { async } from '@angular/core/testing';
import { DOCUMENT } from '@angular/common';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    [x: string]: any;
    public form: FormGroup; 
    public loader = false;
    passwordType: string = 'password';
    href: any
    selectLanguage = 'en';
    translate: any;
    rememberMeControl = new FormControl(false);
    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private localStorage: LocalStorageService,
        public api: ApiService,
        private router: Router,
        public globalservice: GlobalLangService,
        @Inject(DOCUMENT) private document: Document
    ) {
        const language = this.globalservice.messageSource.value || localStorage.getItem('language');
        !language ? this.globalservice.messageSource.next('en') : this.globalservice.messageSource.next(language);
        this.form = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.pattern(this.api.constant.EMAIL_REGEX)])],
            password: ['', Validators.required]
        });
    }

    ngOnInit() {
        let langVal = localStorage.getItem('language'); 
        if(langVal == 'en')
        {
            localStorage.clear();
            this.globalservice.changeLanguageMethod('en') 
        }
        else 
        {
            localStorage.clear();
            this.globalservice.changeLanguageMethod('ar')
        }
        
        //Clear  Local storage
        //localStorage.setItem('language', 'en');
        if (this.localStorage.getItem('rememberMe')) {
            this.rememberMeControl.patchValue(true);
            this.form.patchValue((this.localStorage.getItem('rememberData')));
        }
        this.href = this.router.url;
        let fromEmail = this.href.includes("id");
        if (fromEmail) {
            let id = this.href.slice(10);
            this.api.AcademyPostData(this.api.apiUrl.verifyAccount, { 'id': id }, true)
                .subscribe(serverResponse => {
                },
                    () => {
                        this.loader = false;
                    });
        }
        this.globalservice.languageDataObj.subscribe((data: any) => {
            if (data.LOGIN) {
                this.translate = data.LOGIN;
            }
        })
    }
    loginFun() {
        let usertype;
        if (this.form.valid) {
            this.loader = true;
            this.api.postData('academy/login', this.form.value)
                .subscribe(serverResponse => { 
                    if (this.rememberMeControl.value) {
                        this.localStorage.setItem('rememberMe', this.rememberMeControl.value);
                        this.localStorage.setItem('rememberData', JSON.stringify(this.form.value));
                    } else {
                        this.localStorage.removeItem('rememberMe');
                        this.localStorage.removeItem('rememberData');
                    }
                    this.userService.setUserLocalData(serverResponse.data);
                    if (serverResponse.statusCode == 200) {  //console.log('login ', serverResponse);
                        this.localStorage.setItem('UsrID', serverResponse.data._id);
                        this.localStorage.setItem('emailaddress', serverResponse.data.email);
                        this.localStorage.setItem('usertype', serverResponse.data.userType);
                        if(serverResponse.data.hasOwnProperty('academyId'))
                        {
                            this.localStorage.setItem('mainAcademyId', serverResponse.data.academyId._id);
                            this.localStorage.setItem('acadyid', serverResponse.data.academyId._id);
                        }
                        usertype = serverResponse.data.userType;
                        let body = document.getElementsByTagName('body')[0];
                        body.classList.remove("adminAccess");
                        if (usertype == "academyUser" || usertype == "academyClub") 
                        {
                            if(serverResponse.data.contractStatus == false)
                            {
                                this.localStorage.setItem('academyStatus', 'false');
                            }
                            if(serverResponse.data.contractStatus == true)
                            {
                                this.localStorage.setItem('academyStatus', 'true');
                            }
                            this.router.navigate(['/dboard']);
                        }
                        //if (usertype == "CMS-ADMIN")
                        if (usertype == "academyAdmin")
                        {
                            this.document.body.classList.add('adminAccess');
                            this.router.navigate(['/aboard']);
                        }
                        if (usertype == "academyCoach") {
                            this.localStorage.setItem('coachid', serverResponse.data._id);
                            this.router.navigate(['/dboard']); 
                        }
                    }
                    else 
                    {
                        let msgdata = serverResponse.message;
                        //this.api.custom_toastr_error_msg(msgdata);
                        if(serverResponse.responseType == 'passIncorrect')
                        {
                            this.api.custom_toastr_error_msg(this.translate.passIncorrect);
                        }
                        this.loader = false;
                        localStorage.clear();
                    }
                },
                    (error) => {
                        //console.log('error',error.error);
                        //error.error.message = '';
                        this.loader = false;
                        if(error.error.responseType == 'passIncorrect')
                        {
                            this.api.custom_toastr_error_msg(this.translate.passIncorrect); return false;
                        }
                        if(error.error.responseType == 'emailNotFound')
                        {
                            this.api.custom_toastr_error_msg(this.translate.emailNotFound); return false;
                        }
                        if(error.error.responseType == 'emailNotverified')
                        {
                            this.api.custom_toastr_error_msg(this.translate.emailNotverified); return false;
                        }
                        if(error.error.responseType == 'contractNotActive')
                        {
                            this.api.custom_toastr_error_msg(this.translate.contractNotActive); return false;
                        }
                        
                    });
        }
        else {
            Object.keys(this.form.controls).forEach(key => {
                this.form.controls[key].markAsTouched({ onlySelf: true });
            });
        }
    }

    pswshow() {
        if (this.passwordType == 'password') {
            this.passwordType = 'text';
        }
        else {
            this.passwordType = 'password';
        }
    }

}
