import { MbscModule } from '@mobiscroll/angular';
import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuardService as AuthGuard } from './services/authguard.service';
import { AppComponent } from './app.component';
import { LoginComponent } from './external/login/login.component';
import { CapitalizePipe } from './shared/pipes/capitalizefirst.pipe';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingModule } from 'ngx-loading';
import { ApiService } from './services/api.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ToastrModule } from 'ngx-toastr';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { InterceptorService } from './services/interceptor.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LightboxModule } from 'ngx-lightbox';
import { AsyncPipe } from '@angular/common';
import { ClickOutsideModule } from 'ng-click-outside';
import { TermsComponent } from './external/terms/terms.component';
import { PrivacyComponent } from './external/privacy/privacy.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ExternalAuthguardService } from './services/externalAuthguard.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ReferralComponent } from './external/referral-code/referral.component';
import { ForgotComponent } from './external/forgot/forgot.component';
import { AcadyformComponent } from './external/acadyform/acadyform.component';
import { AcadyformdComponent } from './external/acadyformd/acadyformd.component';
import { AgmCoreModule } from '@agm/core';
import { UcComponent } from './external/uc/uc.component';
import { MaterialModule } from "./shared/material/material.module";

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { GlobalLangService } from './services/global-lang.service';
import { NumberOnlyDirective } from './shared/directives/numberOnly.directive';

import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { EmailVerificationComponent } from './external/email-verification/email-verification.component';
import { PopupComponent } from './popup/popup.component';
import { ResetPSWComponent } from './reset-psw/reset-psw.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { PlayerFilterComponent } from './popup/player-filter/player-filter.component';
import { NotificationComponent } from './popup/notification/notification.component';
import { RatingFilterComponent } from './popup/rating-filter/rating-filter.component';
import { ViewInvoiceComponent } from './popup/view-invoice/view-invoice.component';
import { AcademyRejectComponent } from "./popup/academy-reject/academy-reject.component";
import { SessionFiltersComponent } from './shared/modals/session-filters/session-filters.component';
import { ChartsModule } from 'ng2-charts';
import { CoachFilterComponent } from './popup/coach-filter/coach-filter.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    CapitalizePipe,
    AppComponent,
    LoginComponent,
    TermsComponent,
    PrivacyComponent,
    ReferralComponent,
    ForgotComponent,
    AcadyformComponent,
    AcadyformdComponent,
    UcComponent,
    NumberOnlyDirective,
    EmailVerificationComponent,
    PopupComponent,
    ResetPSWComponent,
    PlayerFilterComponent,
    NotificationComponent,
    RatingFilterComponent,
    ViewInvoiceComponent,
    AcademyRejectComponent,
    SessionFiltersComponent,
    CoachFilterComponent,


  ],
  imports: [ 
    MbscModule,  
    AngularMultiSelectModule,
    PaginationModule.forRoot(),
    GooglePlaceModule,
    Ng2TelInputModule,
    BrowserModule,
    ClickOutsideModule,
    ChartsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxMaterialTimepickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,

    LoadingModule.forRoot({
      primaryColour: '#4D6836',
      tertiaryColour: '#4D6836'
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production
    }),

    CommonModule,
    BrowserAnimationsModule,
    AngularFontAwesomeModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 3000,
      progressAnimation: 'increasing',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      progressBar: true
    }),
    LazyLoadImageModule,
    LightboxModule,
    NgMultiSelectDropDownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCeVe9FyJO3TnWaYjVyvrBQ5wB4Fc6FVsE',
      libraries: ['places']
    }),
    MaterialModule,
    MatSelectCountryModule.forRoot('en'),
    NgxMatSelectSearchModule,




  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    ApiService,
    TranslateService,
    AuthGuard,
    AsyncPipe,
    ExternalAuthguardService,
    GlobalLangService,
    Title
  ],
  bootstrap: [AppComponent],
  entryComponents: [PopupComponent, PlayerFilterComponent, NotificationComponent, RatingFilterComponent, ViewInvoiceComponent, AcademyRejectComponent, SessionFiltersComponent, CoachFilterComponent]
})

export class AppModule {
}
