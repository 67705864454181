import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { forkJoin } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { GlobalLangService } from '../../services/global-lang.service';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.css']
})
export class ViewInvoiceComponent implements OnInit {

  translate: any;
  isEnglish = false;
  currencyEn: any;
  vatIdStatus = false;
  isInvoiceGenerated = false;
  allowToDownload = false;
  vatPercentage = 0;
  apiData: any = [];
  invoiceId: any = '';
  academyName: any = '';
  academyCountry: any = '';
  vatIdNo: any;
  beforeAmount = 0; // the actual amount.. without including VAT
  playerName : any;
  plan : any;
  price : any;
  receiptNumber : any;
  invoiceDate : any;
  invoiceNumber : any;
  totalAmountPaid : any;
  VATamount : any;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<ViewInvoiceComponent>,
    public api: ApiService, @Inject(MAT_DIALOG_DATA) public data: any, private globalService: GlobalLangService,
    @Inject(DOCUMENT) private document: Document) {
      this.globalService.languageDataObj.subscribe((data: any) => {
        if (data.FILTERPLAYERS) {
          this.translate = data.FINANCIAL;
        }
      });
      this.isEnglish = localStorage.getItem('language') === 'en' ? true : false;
     }

  ngOnInit() {
    
    /*this.document.body.classList.add('financialDashboard');*/
    this.onPageLoad();
    
  }

  onPageLoad() {
    console.log(this.data)
    this.invoiceId = this.data.receiptId;
    this.allowToDownload = this.data.allowToDownload;
    const obj: any = {receiptId: this.invoiceId};
      this.api.getData(this.api.apiUrl.invoiceDetails, obj, true)
        .subscribe(res => {
          console.log("player invoiceDetails", res);
          if(res.statusCode == 200)
          {
            this.apiData = res.data[0];
            if(this.apiData.invoice)
            {
              this.vatIdStatus = this.apiData.invoice.vatIdStatus;
              if(this.vatIdStatus == true)
              {
                this.vatPercentage = this.apiData.invoice.vatPercent; 
                this.vatIdNo = this.apiData.invoice.vatIdNo; 
                this.beforeAmount = this.apiData.invoice.beforeAmount; 
                this.VATamount = this.apiData.invoice.totalAmount - this.apiData.invoice.beforeAmount; 
              }
            }
            if(this.apiData.academyId)
            {
              this.academyName = this.apiData.academyId.name;
              this.academyCountry = this.apiData.academyId.country.en;
            }
            if(this.apiData.playerId)
            {
              this.playerName = this.apiData.playerId.fullName;
            }
            if(this.apiData.contract)
            {
              this.currencyEn = this.globalService.getLanguage() == 'en' ? this.apiData.contract.currencyId.nameEn : this.apiData.contract.currencyId.nameAr;
            }
          }
        },
        () => {
        });
  }
  
  downloadInvoicePDF()
  {
    let obj: any = {};
    obj.language = this.globalService.getLanguage();
    obj.receiptId = this.invoiceId;
    this.api.postData(this.api.apiUrl.invoiceDownload, obj)
    .subscribe((res) => {
        console.log('response-data', res);
      if (res.statusCode == 200) {
          window.open(res.data, "_blank");
      }
    },
      (error) => {
        console.log('error data', error);

      });
  }


  close(){
     this.dialogRef.close(); return false;
  }

  ngOnDestroy() {
    /*let body = document.getElementsByTagName('body')[0];
    body.classList.remove("financialDashboard");*/
  } 
}
