import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { GlobalLangService } from '../services/global-lang.service';

@Component({
  selector: 'app-reset-psw',
  templateUrl: './reset-psw.component.html',
  styleUrls: ['./reset-psw.component.css']
})
export class ResetPSWComponent implements OnInit { 


href:any;
public form: FormGroup;
public loader = false;
verifiedSuccess: boolean = false;
notMacthed: boolean = false;
passwordType :string = 'password';
translate: any;
confirm_passwordType :string = 'password';
constructor(
  public api: ApiService,
  private router: Router,
  private formBuilder: FormBuilder,
  public globalservice: GlobalLangService
) { 
  this.form = this.formBuilder.group({
    password: ['', Validators.required],
    passwordConf: ['', Validators.required]
});
const language = this.globalservice.messageSource.value || localStorage.getItem('language');
!language ? this.globalservice.messageSource.next('en') : this.globalservice.messageSource.next(language);
this.globalservice.languageDataObj.subscribe((data: any) => {
  if (data.RESETPASSWORD) {
    this.translate = data.RESETPASSWORD;
  }
});
this.globalservice.changeLanguageMethod('ar');
}

ngOnInit() {
}

forgotFun(){
  
  this.href = this.router.url;
    let fromEmail =  this.href.includes("id"); 
    if(fromEmail && !this.notMacthed){
       let data = {
         id : this.href.slice(19),
         newPassword : this.form.controls['password'].value
       }
       
       
    this.api.postData('academy/changePassword', data)
    .subscribe(serverResponse => 
        {
            console.log('update_pass ', serverResponse);
                if( serverResponse.statusCode == 200){
                  this.verifiedSuccess  = true;
                  this.api.custom_toastr_success_msg(this.translate.passwordChangedSuccess);
                  this.router.navigate(['login'])
                }else{
                 this.verifiedSuccess  = false;
                 let msgdata= serverResponse.msg;
                 this.api.custom_toastr_error_msg(msgdata);
                 this.loader = false;
                }


    },
        () => {
            this.loader = false;
        });    

     
}
}

isMacth(){    
  if(this.form.controls['password'].value == this.form.controls['passwordConf'].value){
    
    this.notMacthed = false;
  }
  else{
    
    this.notMacthed = true;
  }
}
pswshow(){
  if( this.passwordType == 'password'){
      this.passwordType = 'text' ;
  }
  else{
      this.passwordType = 'password' ;
  }
}
confirm_pswshow(){
  if( this.confirm_passwordType == 'password'){
      this.confirm_passwordType = 'text' ;
  }
  else{
      this.confirm_passwordType = 'password' ;
  }
}
}